import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Billing', 'Pages.Billings', 'flaticon-app', '/app/admin/billings'),
            new AppMenuItem('NewsCenter', 'Pages.Administration.News', 'flaticon-envelope', '/app/admin/news-center'),
            new AppMenuItem('GlobalActions', 'Pages.GlobalActions', 'flaticon-list-3', '/app/admin/global-actions'),
            new AppMenuItem('TaxAnalytics', 'TaxAnalytics.View', 'flaticon-graphic-2', '/app/tax-analytics', [], [], undefined, undefined, () => abp.features.isEnabled('TaxAnalyticsFeature')),
            new AppMenuItem('TaxAi', 'TaxAi.View', 'flaticon-signs', '/app/tax-ai', [], [], undefined, undefined, () => abp.features.isEnabled('TaxAiFeature')),
            new AppMenuItem('IRO', 'Iro.Form.View', 'flaticon-file-2', '/app/iro', [], [], undefined, undefined, () => abp.features.isEnabled('IroFeature')),
            new AppMenuItem('AccountMapping', 'AccountMapping.Mapping.View', 'flaticon-coins', '/app/account-mapping', [], [], undefined, undefined, () => abp.features.isEnabled('AccountMappingFeature')),
            new AppMenuItem('RealPropertyTax', 'RealPropertyTax.View', 'flaticon-home-2', '/app/real-property-tax', [], [], undefined, undefined, () => abp.features.isEnabled('RealPropertyTaxFeature')),
            new AppMenuItem('CbCR', 'Cbcr.View', 'flaticon-line-graph', '/app/cbcr', [], [], undefined, undefined, () => abp.features.isEnabled('CbcrFeature')),
            new AppMenuItem('Dac6', 'Dac6.Report.View', 'flaticon-earth-globe', '/app/dac6', [], [], undefined, undefined, () => abp.features.isEnabled('Dac6Feature')),
            new AppMenuItem('Checklists', 'Checklists.View', 'flaticon-list', '/app/taskit', [], [], undefined, undefined, () => abp.features.isEnabled('ChecklistsFeature')),
            new AppMenuItem('Pillar2', 'Pillar2.Project.View', 'flaticon-layer', '/app/pillar2', [], [], undefined, undefined, () => abp.features.isEnabled('Pillar2Feature')),
            new AppMenuItem('MasterDataManagement', 'MasterDataManagement.View', 'flaticon-network', '/app/master-data-management', [], [], undefined, undefined, () => abp.features.isEnabled('MasterDataManagementFeature')),
            new AppMenuItem('Administration', '', 'flaticon-settings', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                //new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                //new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                //new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                //new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Billing', 'Pages.Administration.Billing', 'flaticon-folder-1', '/app/admin/tenant-billing'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
